    import { graphql, Link } from "gatsby";
import React, { useContext } from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import MortgageCalculator from "../components/MortgageSection/MortgageCalculator";
import EquityCalculator from "../components/PropertyDetails/EquityCalculator";
import "../components/ContactForm/ContactForm.scss";
import AccordionContext from "react-bootstrap/AccordionContext";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { Card, Accordion } from 'react-bootstrap';
import SEO from "../components/seo";

const CalculatorTemplate = (props) => {
    const { calculator } = props.data.glstrapi;
    function ContextAwareToggle({ children, eventKey, callback }) {
        const currentEventKey = useContext(AccordionContext);
        const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
        const isCurrentEventKey = currentEventKey === eventKey;
        return (
            <h3
                className={isCurrentEventKey ? "active card-header position-relative" : "card-header position-relative"}
                onClick={decoratedOnClick}
            >
                {children}
                <i className={isCurrentEventKey ? "icon-caretdown up" : "icon-caretdown"}></i>
            </h3>
        );
    }

    var sectionStyle = {
        backgroundImage: `url(${calculator?.Banner_Image?.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
      };

    return (
        <div>
            <SEO
                title={calculator.Meta_Title}
                description={calculator.Meta_Description}
                location={props.location}
            />
            <Header headerTwo={true}/>
                <div className="contact-form-sec">
                {/* <img className={`contact-bg cal-${calculator?.Title?.toLowerCase().split(" ").join("-")}`} src={calculator?.Banner_Image?.url} alt={calculator?.Title + " - Espace"} /> */}
                    <div className={`contact-bg cal-${calculator?.Title?.toLowerCase().split(" ").join("-")}`} style={sectionStyle}>
                    <div className="contact-form calculator-detail">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-7 col-lg-6 col-12 bg-white">
                                    {calculator?.Title === "Mortgage Calculator" && <MortgageCalculator
                                        calculator={calculator}
                                    />}
                                    {/* This supposed to be unchange item, title can be change */}
                                    {calculator?.Title === "Net Return on Equity Calculator" && <EquityCalculator
                                        calculator={calculator}
                                    />}
                                </div>
                                <div className="col-xl-5 col-lg-6 col-12 bg-grey p-xl-5 p-4 calculator-details">
                                    <h1>{calculator?.Content_Title}</h1>
                                    <p>{calculator?.Description}</p>
                                    <div className="menu-accordian-boxes">
                                        {calculator?.Accordion_Details.map((each, i) =>
                                            <Accordion className="menu-accordian">
                                                <Card>
                                                    <div>
                                                        <ContextAwareToggle
                                                            eventKey={`${i}`}
                                                            className="accordion-header"
                                                        >
                                                            {each.Title}
                                                        </ContextAwareToggle>
                                                    </div>

                                                    <Accordion.Collapse eventKey={`${i}`}>
                                                        <div className="accordion-card accordion-card-first card-body">
                                                            <p>{each.Description}</p>
                                                        </div>
                                                </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        )}
                                    </div>
                                    <h3 className="calc-need-more mt-5 pe-xl-5 pe-0">Need more help? Why not speak with one of our finance team.</h3>
                                    <Link to="/contact/contact-us/" className="w-50 mt-4">
                                        <button className="btn subscribe-btn">contact us</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            <Footer/>
        </div>
    )
}

export default CalculatorTemplate;

export const pageQuery = graphql`
    query calculatorQuery($id: ID!) {
        glstrapi {
            calculator(id: $id, publicationState: LIVE) {
                id
                URL
                Title
                Tile_Image {
                    url
                    alternativeText
                }
                Banner_Image {
                    url
                    alternativeText
                }
                Speak_with_Title
                Speak_with_Team {
                    Email
                    Name
                    Phone
                    Tile_Image {
                    url
                    alternativeText
                    }
                    Designation
                }
                Meta_Title
                Meta_Description
                Description
                Content_Title
                Content_Discription
                Accordion_Details {
                    id
                    Title
                    Sort
                    Description
                }
            }
        }
    }
`
