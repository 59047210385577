import { Link } from "gatsby";
import React, {useState} from "react";
import { Button, Form, Range } from "react-bootstrap";
//import RangeSlider from "../RangeSlider/RangeSlider";
import "../MortgageCalculator/MortgageCalculator.scss";
import AgentImg from "../../../images/profile-pic.jpg";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { calculateMonthlyPayment } from "../../calculators/calculators";
import { filterNumber, numberFormat, pmt } from "../../utils";

const Calculator = (props) => {

    const [year, setYear] = useState();
    const [price, setPrice] = useState();
    const [rent, setRent] = useState();
    const [mortgageLTV, setMortgageLTV] = useState();
    const [mortgageInt, setMortgageInt] = useState();
    const [charge, setCharge] = useState();
    const [result, setResult] = useState({
        capitalInvestment: '',
        morgageDebt: '',
        morgageInterest: '',
        netSaving: '',
        netReturn: ''
    })

    function isNumber(evt) {
        return true;
    }

    function comma(Num) { //function to add commas to textboxes
        Num += '';
        Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
        Num = Num.replace(',', ''); Num = Num.replace(',', ''); Num = Num.replace(',', '');
        let x = Num.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1))
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
        return x1 + x2;
    }

    const handleSubmit = () => {

        if (!year || !price || !rent || !mortgageLTV || !mortgageInt || !charge)
            return false;

        // Ref: https://espace.ae/living-in-dubai/net-return-equity-calculator/
        // Capital Requirement
        // SUM(price*((100-(mortgageLTV*100))/100)+(price*0.06))
        let cap_patch1 = parseFloat(price)*0.06;
        let cap_patch2 = (100-parseFloat(mortgageLTV))/100;
        let capital_requirement = price*cap_patch2+cap_patch1;

        // Mortgage Debt
        // SUM(price*mortgageLTV)
        let mortgage_debt = (price*mortgageLTV)/100;
        if (mortgage_debt)
            mortgage_debt = mortgage_debt //.toFixed(2)

        // Yearly Mortgage Interest
        // SUM(Mortgage Debt*(Mortgage Interest/100))
		let yearly_mortgageInt = (mortgage_debt*parseFloat(mortgageInt))/100; // toFixed(2)


        // Net Saving
        // SUM(C4-C7-C10)
		let net_saving = parseFloat(rent) - parseFloat(charge) - parseFloat(yearly_mortgageInt);


        // Net Return on Equity
        // SUM((C11/C8)*100)-(6/C2)
		let patch2 = 6/year;
		let patch1 = net_saving/capital_requirement;
		let patch3 = patch1*100;

		let net_return_on_capital = patch3 - patch2;

        setResult({
            capitalInvestment: comma(capital_requirement) || 0,
            morgageDebt: comma(mortgage_debt) || 0,
            morgageInterest: yearly_mortgageInt || 0,
            netSaving: net_saving || 0,
            netReturn: net_return_on_capital || 0
        })
    }

    React.useEffect(() => {
        handleSubmit();
    }, [year, price, rent, mortgageLTV, mortgageInt, charge])

    React.useEffect(() => {
        handleSubmit();
    }, [])

    return (
        <React.Fragment>
            <div className="mortgage-calculator">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <h2 className="main-title">Equity Calculator</h2>
                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Years of Ownership:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            value={year}
                            onChange={(e) => {
                                if (isNumber(e.target.value))
                                    setYear(e.target.value)
                            }}
                        />
                    </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Property Price:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            value={price}
                            onChange={(e) => {
                                if (isNumber(e.target.value))
                                    setPrice(e.target.value)
                            }}
                        />
                        <span className="unit-txt">AED</span>
                    </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Market Rent:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            value={rent}
                            onChange={(e) => {
                                if (isNumber(e.target.value))
                                    setRent(e.target.value)
                            }}
                        />
                        <span className="unit-txt">AED</span>
                    </div>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Mortgage LTV:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            value={mortgageLTV}
                            onChange={(e) => {
                                if (isNumber(e.target.value))
                                    setMortgageLTV(e.target.value)
                            }}
                        />
                        <span className="unit-txt">%</span>
                    </div>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Mortgage Interest:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            value={mortgageInt}
                            onChange={(e) => {
                                if (isNumber(e.target.value))
                                    setMortgageInt(e.target.value)
                            }}
                        />
                        <span className="unit-txt">%</span>
                    </div>
                </Form.Group>

                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Service charge:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            value={charge}
                            onChange={(e) => {
                                if (isNumber(e.target.value))
                                    setCharge(e.target.value)
                            }}
                        />
                        <span className="unit-txt">AED</span>
                    </div>
                </Form.Group>

                {(!!price && !!rent && !!mortgageLTV && !!mortgageInt && !!charge) && (
                    <Form.Group className="monthly-pay">
                        <Form.Label className="price-label">Capital Requirement: {result.capitalInvestment}</Form.Label>
                        <Form.Label className="price-label">Mortgage Debt: {result.morgageDebt}</Form.Label>
                        <Form.Label className="price-label">Yearly Mortgage Interest: {result.morgageInterest}</Form.Label>
                        <Form.Label className="price-label">Net Saving: {result.netSaving}</Form.Label>
                        <Form.Label className="price-label">Net Return on Equity: {result.netReturn}</Form.Label>
                    </Form.Group>
                )}
                {(props.calculator && props.calculator.Speak_with_Team.length > 0) && <div className="contact-agent">
                    <div className="contact-agent-photo">
                        <img src={props.calculator.Speak_with_Team[0]?.Tile_Image?.url} alt="" />
                    </div>
                    <div className="contact-agent-info">
                        <span className="contact-agent-name">{props?.calculator?.Speak_with_Title} <span className="ms-1">{props?.calculator?.Speak_with_Team[0]?.Name}</span></span>
                        {props.calculator.Speak_with_Team[0]?.Phone && (
                            <div className="agent-num-wrp">
                                <Link href={`tel:${props.calculator.Speak_with_Team[0]?.Phone}`} className="contact-agent-number">{props.calculator.Speak_with_Team[0]?.Phone}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>}
                </ScrollAnimation>
            </div>
        </React.Fragment>
    );
};
export default Calculator;
